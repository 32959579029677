import {
  APP_INSTALLED_ACTION,
  BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  ECOM_APP_DEF_ID,
  FedopsInteractions,
  MEMBERS_AREA_APP_DEF_ID,
  STORES_APP_DEF_ID,
  STUDIO_BRAND,
  STUDIO_SUBTYPE,
} from './constants';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomComponent, withEcomPlatform} from '@wix/ecom-platform-sdk';
import {doTransaction} from '../editor-script/transaction';
import {applicationActionBiEvent, ecomAppDefID, Events, SPECS} from '../editor-script/constants';
import {ExperimentsApi} from '../common/experiments/ExperimentsApi';
import {delay} from '@wix/wixstores-client-core/dist/src/utils/delay';
import {isMembersAreaInstalled, withMembersArea} from '@wix/members-area-integration-kit';
import {translateFunctionFactory} from '../common/translations/translations';
import {getAppManifest, handleAppsManagerActions} from '../common/appManifest/appManifest';
import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {ContextParams, EditorSDK, EditorType, IGetDataByAppDef, PanelResolveType} from '@wix/platform-editor-sdk';
import {
  errorReporter,
  setSentryInstance,
  withErrorReportingWrapping,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/errorReporter';
import {createSentryInstance} from '../common/monitoring';
import {EcomPublicApi} from '../editor-script/editorScript';
import {missingPagesMigration} from '../editor-script/migration-scripts/missingPagesMigration';
import {createOOIWebBILogger} from '@wix/wixstores-client-core';
import webLogger from '@wix/web-bi-logger';
import {Logger as WebBiLogger} from '@wix/web-bi-logger/dist/src/types';
import {
  exposureEventForTests,
  pagesAtAppInstallation,
  clickOnSwitchToCategoriesModalSf,
  clickSwitchToCategoriesVerificationModalSf,
  manuallyOptInToCategoriesSf,
  clickSwitchToCategoriesConfirmationModalSf,
  newClickOnItemInShopPagesSettingSf,
} from '@wix/bi-logger-ec-sf/v2';
import {markSiteWithCategoryPageStatus} from './utils/categories/categoriesRolloutManagerUtils';
import {
  addAllProductsCategoryToDefaultSiteMenu,
  createCategoryPageRouter,
  getCategoryPageSiteStatus,
  shouldInstallCategoryPage,
} from './utils/categories/categoriesinstallationUtils';
import {getAppVersion} from '@wix/wixstores-client-core/dist/src/app-version/appVersion';
import {shouldInstallProductPageOnBlocks} from './utils/blocksProductPage';
import {WIX_NEW_STORES} from '@wix/app-definition-ids';

const PROGRESS_BAR_MIN_STEP_DELAY = 1000;
const ecomComponents: EcomComponent[] = [
  EcomComponent.CART_ICON,
  EcomComponent.CART,
  EcomComponent.CHECKOUT,
  EcomComponent.THANK_YOU_PAGE,
];
let sdk: EditorSDK;
let t: (key: string) => string;
let installationStep = 0;
let actualProgressBarStep = 0;
let experimentsApi: ExperimentsApi;
let locale: string = 'en';
let options: ContextParams;
let isCategorySite: boolean;
let numOfShopPages = 0;
let hasCategoryPage = false;
let hasBlocksProductPage = false;
let isWixStudio = false;
let isClassic = false;
let ecomPublicApi: EcomPublicApi;
let webBiLogger: WebBiLogger;
let fedopsLogger: Record<string, any>;

async function getEcomPagesData() {
  const ecomApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ECOM_APP_DEF_ID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  return allSitePages.filter((page) => page.tpaApplicationId === ecomApplicationId);
}

async function checkIfPageExists(page: PageMap) {
  const ecomPagesData = await getEcomPagesData();
  return !!ecomPagesData.find((p) => p.tpaPageId === page);
}

function checkIfBlocksProductPageExists(): Promise<boolean> {
  return sdk.application.isApplicationInstalled('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
}

async function installBlocksProductPage() {
  hasBlocksProductPage = true;
  fedopsLogger?.interactionStarted?.(FedopsInteractions.BlocksProductPageAppInstallation);
  await sdk.document.tpa.add.application('', {
    appDefinitionId: BLOCKS_PRODUCT_PAGE_APP_DEF_ID,
  });
  fedopsLogger?.interactionEnded?.(FedopsInteractions.BlocksProductPageAppInstallation);
}

async function addOoiProductPage() {
  fedopsLogger?.interactionStarted?.(FedopsInteractions.ProductAddOoiPage);
  await ecomPublicApi.addPage(PageMap.PRODUCT, STORES_APP_DEF_ID);
  fedopsLogger?.interactionEnded?.(FedopsInteractions.ProductAddOoiPage);
}

async function addCategoryPage() {
  isCategorySite = true;
  hasCategoryPage = true;
  fedopsLogger?.interactionStarted?.(FedopsInteractions.CategoryAddPage);
  const categoryPageRef = await ecomPublicApi.addPage(PageMap.CATEGORY, STORES_APP_DEF_ID, true);
  fedopsLogger?.interactionEnded?.(FedopsInteractions.CategoryAddPage);
  const router = await createCategoryPageRouter(ecomPublicApi, categoryPageRef, fedopsLogger);
  await markSiteWithCategoryPageStatus(options.essentials.httpClient, ecomPublicApi.getInstance(), true);
  return {categoryPageRef, router};
}

function addStoresPages(editorSdk = sdk) {
  return doTransaction(editorSdk, async () => {
    ecomPublicApi = (await editorSdk.application.getPublicAPI('', {
      appDefinitionId: ECOM_APP_DEF_ID,
    })) as EcomPublicApi;
    const installProductPageOnBlocks = shouldInstallProductPageOnBlocks(experimentsApi, isWixStudio);

    if (
      (installProductPageOnBlocks && (await checkIfBlocksProductPageExists())) ||
      (!installProductPageOnBlocks && (await checkIfPageExists(PageMap.PRODUCT)))
    ) {
      installationStep++;
      return Promise.resolve();
    }

    await provisionStore(ecomPublicApi.getInstance());

    if (
      shouldInstallCategoryPage(
        options.origin.type,
        isCategorySite,
        experimentsApi.enabled(SPECS.EnableCategoriesPageInEditorX),
        isWixStudio
      )
    ) {
      fedopsLogger?.interactionStarted?.(FedopsInteractions.CategoryPageAndRouterInstallationProcess);
      const {categoryPageRef, router} = await addCategoryPage();
      fedopsLogger?.interactionEnded?.(FedopsInteractions.CategoryPageAndRouterInstallationProcess);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      webBiLogger.report(
        pagesAtAppInstallation({
          pageName: 'Categories page',
          initiatorName: options.origin.type,
          msid: options.initialAppData.metaSiteId,
        })
      );

      fedopsLogger?.interactionStarted?.(FedopsInteractions.CategoryAddPageToSiteMenu);
      await addAllProductsCategoryToDefaultSiteMenu(editorSdk, t, router, categoryPageRef);
      fedopsLogger?.interactionEnded?.(FedopsInteractions.CategoryAddPageToSiteMenu);
    } else {
      await markSiteWithCategoryPageStatus(options.essentials.httpClient, ecomPublicApi.getInstance(), false);
      await ecomPublicApi.addPage(PageMap.GALLERY, STORES_APP_DEF_ID, true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      webBiLogger.report(
        pagesAtAppInstallation({
          pageName: 'Shop page',
          initiatorName: options.origin.type,
          msid: options.initialAppData.metaSiteId,
        })
      );
    }

    const productPagePromise = installProductPageOnBlocks ? installBlocksProductPage() : addOoiProductPage();

    await productPagePromise;
    await ecomPublicApi.setStateForStoresPages();
    installationStep++;
  });
}

async function startInstallationProgressBar() {
  const progressBarStepTitles = [
    '',
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textOne'),
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textTwo'),
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textThree'),
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textFour'),
    t('memberPages.addModal.memberPagesTab.installation.popup.loader.textFive'),
  ];

  try {
    const totalSteps = options.origin.type === EditorType.ADI ? 4 : 5;
    await sdk.editor.openProgressBar('', {
      title: t('memberPages.addModal.memberPagesTab.installation.popup.title'),
      totalSteps,
      currentStep: 0,
      stepTitle: progressBarStepTitles[0],
    });

    while (actualProgressBarStep < totalSteps) {
      if (installationStep > actualProgressBarStep) {
        actualProgressBarStep++;
      }
      await delay(PROGRESS_BAR_MIN_STEP_DELAY);
      await sdk.editor.updateProgressBar('', {
        currentStep: actualProgressBarStep,
        stepTitle: progressBarStepTitles[actualProgressBarStep],
      });
    }

    await sdk.editor.updateProgressBar('', {
      currentStep: actualProgressBarStep,
      stepTitle: progressBarStepTitles[actualProgressBarStep],
    });
    await delay(PROGRESS_BAR_MIN_STEP_DELAY);
    await sdk.editor.closeProgressBar('', {});
  } catch (e) {
    return;
  }
}

async function migrateEcomPagesToStores() {
  const allSitePages = await sdk.pages.data.getAll('');
  const ecomPages = allSitePages.filter(({managingAppDefId}) => managingAppDefId === ecomAppDefID);
  if (ecomPages.some((page) => page.tpaPageId === PageMap.PRODUCT)) {
    return ecomPages.map((page) => {
      if (page.tpaPageId === PageMap.CHECKOUT) {
        return Promise.resolve();
      }
      return sdk.pages.data.update('', {
        pageRef: {id: page.id, type: 'DESKTOP'},
        data: {managingAppDefId: STORES_APP_DEF_ID},
      });
    });
  }
}

async function getNumOfShopPages(isRemovingShopPage?: boolean) {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  numOfShopPages = ecomPages.filter((page) => page.tpaPageId?.startsWith(PageMap.GALLERY)).length;
  if (isRemovingShopPage && numOfShopPages > 1) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    webBiLogger.report(
      exposureEventForTests({
        testName: 'removeShopPage',
        is_eligible: true,
        bi_token: options.initialAppData.metaSiteId,
        appState: JSON.stringify({
          allSitePages: allSitePages.map((page) => {
            return {
              id: page?.id || '',
              title: page?.title || '',
              tpaPageId: page?.tpaPageId || '',
            };
          }),
          ecomPages: ecomPages.map((page) => {
            return {
              id: page?.id || '',
            };
          }),
          numOfShopPages,
        }),
      })
    );
  }
  return numOfShopPages;
}

async function hasCategoryPageOnSite() {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  return !!ecomPages.find((page) => page.tpaPageId?.startsWith(PageMap.CATEGORY));
}

async function getProductPageRef() {
  const tpaApplicationId = (await sdk.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await sdk.pages.data.getAll('');
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);
  const productPage = ecomPages.find((page) => page.tpaPageId?.startsWith(PageMap.PRODUCT));
  return await sdk.tpa.getPageRefByTPAPageId('', {tpaPageId: productPage?.tpaPageId});
}

function sendBiForApplicationAction(eventType: Events) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  webBiLogger.report(
    newClickOnItemInShopPagesSettingSf({
      category: applicationActionBiEvent[eventType],
      bi_token: options.initialAppData.metaSiteId,
    })
  );
}

async function initExperiments() {
  if (!experimentsApi?.experiments) {
    experimentsApi = new ExperimentsApi();
    await experimentsApi.fetch(false);
  }
}

async function editorReady(editorSDK: EditorSDK, _appToken: string, _options: ContextParams) {
  try {
    options = _options;
    sdk = editorSDK;
    isWixStudio = (options.origin as any)?.subType === STUDIO_SUBTYPE;
    isClassic = options.origin.type === EditorType.Classic;

    locale = (await sdk.editor.environment.getLocale()) || locale;
    t = await translateFunctionFactory(locale);

    await initExperiments();

    if (experimentsApi.enabled(SPECS.FedopsBlocksInstallation)) {
      fedopsLogger = options?.monitoring?.createFedopsLogger()('stores-editor-script');
    }

    if (options.firstInstall) {
      fedopsLogger?.interactionStarted?.(FedopsInteractions.StoresInstallationEditorReady);
      fedopsLogger?.interactionStarted?.(FedopsInteractions.StoresInstallationAppInstalled);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      startInstallationProgressBar();
    }

    setSentryInstance(createSentryInstance(options.monitoring));

    if (experimentsApi.enabled(SPECS.StoresInstallationStudioFromCommonConfig)) {
      isWixStudio = (self as any)?.commonConfig?.brand === STUDIO_BRAND;
    }

    const user = {uuid: options.initialAppData.userId};
    webBiLogger = createOOIWebBILogger({
      user,
      biLoggerFactory: webLogger.factory,
      biToken: null,
      defaults: {
        storeId: options.initialAppData.instanceId,
        isMerchant: true,
        appName: 'wixstores worker',
      },
    });

    const isEcomPlatformInstalled = await sdk.application.isApplicationInstalled('', {
      appDefinitionId: ECOM_APP_DEF_ID,
    });

    if (isEcomPlatformInstalled) {
      fedopsLogger?.interactionStarted?.(FedopsInteractions.CategoryRolloutManagerStatusCall);
      isCategorySite = await getCategoryPageSiteStatus(experimentsApi, options);
      fedopsLogger?.interactionEnded?.(FedopsInteractions.CategoryRolloutManagerStatusCall);

      fedopsLogger?.interactionStarted?.(FedopsInteractions.StoresPagesStatusCheck);
      numOfShopPages = await getNumOfShopPages();
      hasCategoryPage = await hasCategoryPageOnSite();
      hasBlocksProductPage = await checkIfBlocksProductPageExists();
      fedopsLogger?.interactionEnded?.(FedopsInteractions.StoresPagesStatusCheck);

      installationStep++;
      if (options.firstInstall) {
        fedopsLogger?.interactionStarted?.(FedopsInteractions.StoresPagesInstallationProcess);
        await addStoresPages(sdk);
        fedopsLogger?.interactionEnded?.(FedopsInteractions.StoresPagesInstallationProcess);
      }
    }

    const membersAreaInstalled = await isMembersAreaInstalled();
    if (membersAreaInstalled) {
      installationStep++;
    }
    installationStep++;

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    await sdk.addEventListener('siteWasSaved', async () => {
      const appData = (await sdk.tpa.app.getDataByAppDefId('', ECOM_APP_DEF_ID)) as IGetDataByAppDef & {
        permissions: {revoked: boolean};
      };
      if (appData?.permissions.revoked) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        webBiLogger.report(exposureEventForTests({testName: 'siteWasSaved', is_eligible: true}));
        return sdk.application.uninstall('', {openConfirmation: false});
      }
    });

    ecomPublicApi = (await sdk.application.getPublicAPI('', {appDefinitionId: ECOM_APP_DEF_ID})) as EcomPublicApi;
    if (experimentsApi.enabled('specs.stores.MissingPagesMigration') && !options.firstInstall && ecomPublicApi) {
      await missingPagesMigration(sdk, ecomPublicApi, options, webBiLogger);
    }

    await migrateEcomPagesToStores();

    await ecomPublicApi?.setStateForStoresPages();

    if (options.firstInstall) {
      fedopsLogger?.interactionEnded?.(FedopsInteractions.StoresInstallationEditorReady);
    }
  } catch (e) {
    errorReporter(e);
  }
}

async function handleAction({type, payload}, editorSdk: EditorSDK) {
  if (!experimentsApi?.experiments) {
    experimentsApi = new ExperimentsApi();
    await experimentsApi.fetch(false);
  }

  if (type === APP_INSTALLED_ACTION && payload?.appDefinitionId === STORES_APP_DEF_ID) {
    installationStep++;
    fedopsLogger?.interactionEnded?.(FedopsInteractions.StoresInstallationAppInstalled);
  }

  if (type === APP_INSTALLED_ACTION && payload?.appDefinitionId === ECOM_APP_DEF_ID) {
    installationStep++;
    isCategorySite = await getCategoryPageSiteStatus(experimentsApi, options);
    await addStoresPages(editorSdk);
  }

  if (type === APP_INSTALLED_ACTION && payload?.appDefinitionId === MEMBERS_AREA_APP_DEF_ID) {
    installationStep++;
  }

  if (
    experimentsApi.enabled(SPECS.MembersAreaPostInstallationNavigationHack) &&
    type === APP_INSTALLED_ACTION &&
    (isWixStudio || isClassic) &&
    payload?.appDefinitionId === WIX_NEW_STORES
  ) {
    const categoryPageRef = await editorSdk.tpa.getPageRefByTPAPageId('', {tpaPageId: PageMap.CATEGORY});
    await editorSdk.document.pages.navigateTo('', {pageRef: categoryPageRef});
  }
}

function onEvent({eventType, eventPayload}: {eventType: Events; eventPayload: any}) {
  switch (eventType) {
    case Events.manageStores:
      sendBiForApplicationAction(eventType);
      return sdk.editor.openDashboardPanel('', {url: 'products', closeOtherPanels: false});
    case Events.appActionClicked:
      return handleAppsManagerActions(sdk, eventPayload.actionId);
    case Events.addShopPage:
      sendBiForApplicationAction(eventType);
      return addShopPage();
    case Events.addCategoryPage:
      sendBiForApplicationAction(eventType);
      return addCategoryPageToExistingSite();
    case Events.addBlocksProductPage:
      //sendBiForApplicationAction(eventType);
      return addBlocksProductPageToExistingSite();
    case Events.deleteShopPage:
      return onRemoveShopPage(eventPayload);
    case Events.deleteApp:
      sendBiForApplicationAction(eventType);
      return onRemoveApp();
  }
}

async function provisionStore(instance) {
  return fetch('/_api/wix-ecommerce-renderer-web/store-manager/provision-store', {
    method: 'post',
    headers: {
      Authorization: instance,
    },
  });
}

async function onRemoveShopPage(eventPayload) {
  numOfShopPages = await getNumOfShopPages();
  hasCategoryPage = await hasCategoryPageOnSite();

  if (
    numOfShopPages > 1 ||
    (experimentsApi.enabled(SPECS.AllowRemovingLastShopWhenCategoryExists) && hasCategoryPage)
  ) {
    const {pageRef} = eventPayload;
    return doTransaction(sdk, async () => {
      await sdk.pages.remove('', {pageRef});
      numOfShopPages--;
      await sdk.application.reloadManifest();
    });
  } else {
    await markSiteWithCategoryPageStatus(options.essentials.httpClient, ecomPublicApi.getInstance(), false);
    return doTransaction(sdk, () => sdk.application.uninstall('', {openConfirmation: true}));
  }
}

async function onRemoveApp() {
  await markSiteWithCategoryPageStatus(options.essentials.httpClient, ecomPublicApi.getInstance(), !!isCategorySite);
  return doTransaction(sdk, () => sdk.application.uninstall('', {openConfirmation: true}));
}

async function addShopPage() {
  try {
    await sdk.editor.openProgressBar('', {
      title: t('Installation_Popup_Loader_shop_page_Header'),
      totalSteps: 2,
      currentStep: 1,
      stepTitle: t('Installation_Popup_Loader_shop_page_Text1'),
    });
  } catch (_) {
    // editor bug after installation the panel is still open
  }

  ecomPublicApi = (await sdk.application.getPublicAPI('', {
    appDefinitionId: ECOM_APP_DEF_ID,
  })) as EcomPublicApi;
  const addPromise = doTransaction(sdk, async () => {
    await ecomPublicApi.addPage(PageMap.GALLERY, STORES_APP_DEF_ID, true);
    numOfShopPages++;
  });
  await delay(PROGRESS_BAR_MIN_STEP_DELAY);
  await sdk.editor.updateProgressBar('', {
    currentStep: 2,
    stepTitle: t('Installation_Popup_Loader_shop_page_Text2'),
  });
  await addPromise;
  await sdk.editor.closeProgressBar('', {});
}

async function addCategoryPageToExistingSite() {
  const getAssetsUrl = () =>
    `https://static.parastorage.com/services/wixstores-client-worker/${getAppVersion()}/assets`;

  try {
    const firstPanelAction = await sdk.editor.openPromotionalPanel('', {
      titleText: t('categoryPage.optInFlow.step1.modal.title'),
      illustration: `${getAssetsUrl()}/images/category-opt-in-1.svg`,
      contentArray: [t('categoryPage.optInFlow.step1.modal.bullet1'), t('categoryPage.optInFlow.step1.modal.bullet2')],
      mainActionText: t('categoryPage.optInFlow.step1.modal.switch.button'),
      footnote: t('categoryPage.optInFlow.step1.modal.footer.text'),
      footnoteLink: t('categoryPage.optInFlow.step1.modal.learnMoreURL'),
      footnoteLinkText: t('categoryPage.optInFlow.step1.modal.learnMoreLink'),
    } as any);

    if (firstPanelAction !== PanelResolveType.MAIN_ACTION) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      webBiLogger.report(
        clickOnSwitchToCategoriesModalSf({
          action: 'Cancel',
          bi_token: options.initialAppData.metaSiteId,
        })
      );
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    webBiLogger.report(
      clickOnSwitchToCategoriesModalSf({
        action: 'Switch',
        bi_token: options.initialAppData.metaSiteId,
      })
    );

    const confirmationModalAction = await sdk.editor.openConfirmationPanel('', {
      headerText: t('categoryPage.optInFlow.step2.modal.title'),
      shouldShowIllustration: true,
      illustration: `${getAssetsUrl()}/images/category-opt-in-2.svg`,
      descriptionText: t('categoryPage.optInFlow.step2.modal.body'),
      mainActionText: t('categoryPage.optInFlow.step2.modal.switch.button'),
      secondaryActionText: t('categoryPage.optInFlow.step2.modal.goBack.button'),
    });
    if (confirmationModalAction !== PanelResolveType.MAIN_ACTION) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      webBiLogger.report(
        clickSwitchToCategoriesVerificationModalSf({
          action: 'Cancel',
          bi_token: options.initialAppData.metaSiteId,
        })
      );
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    webBiLogger.report(
      clickSwitchToCategoriesVerificationModalSf({
        action: 'Switch',
        bi_token: options.initialAppData.metaSiteId,
      })
    );

    await sdk.editor.openProgressBar('', {
      title: t('categoryPage.optInFlow.installation.loader.title'),
      totalSteps: 2,
      currentStep: 0,
      stepTitle: t('categoryPage.optInFlow.installation.loader.text1'),
    });

    await delay(PROGRESS_BAR_MIN_STEP_DELAY);

    await addCategoryPage();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    webBiLogger.report(
      manuallyOptInToCategoriesSf({
        bi_token: options.initialAppData.metaSiteId,
      })
    );

    await sdk.editor.updateProgressBar('', {
      currentStep: 1,
      stepTitle: t('categoryPage.optInFlow.installation.loader.text2'),
    });

    await delay(PROGRESS_BAR_MIN_STEP_DELAY * 2);

    await sdk.application.reloadManifest();

    await sdk.editor.updateProgressBar('', {
      currentStep: 2,
    });

    await delay(PROGRESS_BAR_MIN_STEP_DELAY);

    await sdk.editor.closeProgressBar('', {});

    await delay(PROGRESS_BAR_MIN_STEP_DELAY);

    const successModalAction = await sdk.editor.openPromotionalPanel('', {
      titleText: t('categoryPage.optInFlow.step3.modal.title'),
      subtitleText: t('categoryPage.optInFlow.step3.modal.body'),
      illustration: `${getAssetsUrl()}/images/category-opt-in-3.svg`,
      contentArray: [],
      mainActionText: t('categoryPage.optInFlow.step3.modal.goToDashboard.button'),
      footnote: ' ',
      footnoteLink: t('categoryPage.optInFlow.step3.modal.learnMoreURL'),
      footnoteLinkText: t('categoryPage.optInFlow.step3.modal.learnMoreLink'),
    } as any);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    webBiLogger.report(
      clickSwitchToCategoriesConfirmationModalSf({
        action: successModalAction === PanelResolveType.MAIN_ACTION ? 'Go to Dashboard' : 'Cancel',
        bi_token: options.initialAppData.metaSiteId,
      })
    );

    if (successModalAction === PanelResolveType.MAIN_ACTION) {
      await sdk.editor.openDashboardPanel('', {url: 'store/categories/list', closeOtherPanels: false});
    }
  } catch (_) {
    // editor bug after installation the panel is still open
  }
}

async function addBlocksProductPageToExistingSite() {
  const productPageRef = await getProductPageRef();
  await sdk.pages.remove('', {pageRef: productPageRef, shouldShowEditorRemovePanel: false});
  await installBlocksProductPage();
  return sdk.application.reloadManifest();
}

const editorScript = {
  editorReady,
  handleAction,
  onEvent,
  getAppManifest: ({appManifestBuilder}: {appManifestBuilder: AppManifestBuilder}, editorSDK: EditorSDK) => {
    return getAppManifest({
      t,
      locale,
      token: '',
      hasStoresPremium: experimentsApi.hasStoresPremium,
      exps: experimentsApi.experiments,
      appManifestBuilder,
      editorSDK,
      options,
      pagesPanelTitle: t('Pages_Panel_storesPages'),
      extraParams: {isCategorySite, numOfShopPages, hasCategoryPage, hasBlocksProductPage},
    });
  },
};

export const storesEditorScript = withErrorReportingWrapping(
  withMembersArea(withEcomPlatform(editorScript as any, ecomComponents), {
    installAutomatically: false,
  })
);
